.center{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(circle at 0 0,#373b52,#252736 51%,#1d1e26);
}
.paddings{
    padding: 1rem;
}

